import React from "react"
import { Link } from "gatsby"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import CustomNavTitle from "../../../components/CustomNavTitle"

import { theme } from "../../../styles/theme"

const CMVIndex = ({ location }) => {
  return (
    <Layout>
      <SEO title="Specific Requirements - cmv-negative-components" />

      <CustomNavTitle
        title="Specific Requirements"
        destination="/requirements/"
        location={location}
      />

      <div className="mt-gap-2" css={theme.components.row}>
        <p className="mb-gap-2 max-w-content">
          Cytomegalovirus (CMV) infection can cause serious morbidity in
          immunocompromised CMV-negative patients. The virus is associated with
          white blood cells. All UK blood components have been leucodepleted at
          the point of manufacture since 1999. This leucodepletion provides a
          significant degree of CMV risk reduction. However, there is still a
          small group of patients for whom CMV negative cellular blood
          components (red cells, platelets and granulocytes) are recommended.
        </p>

        <ul css={theme.components.linkButtonList}>
          <li>
            <Link
              to="/requirements/cmv-negative-components/elective"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Elective transfusions during pregnancy
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/cmv-negative-components/iut-neonates"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Intrauterine transfusion (IUT) and neonates
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/cmv-negative-components/granulocyte-transfusion"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Patients requiring granulocyte transfusion
            </Link>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default CMVIndex
